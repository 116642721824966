import Button from '@gameonsports/components/cjs/Button'
import Icon from '@gameonsports/components/cjs/Icon'
import Input from '@gameonsports/components/cjs/Input'
import { Field, FieldProps, FormikProps } from 'formik'
import React from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'
import { media } from '../../utils/styled-components-utils'

const SearchContainer = styled.div`
  position: relative;
`

const SearchIcon = styled(Icon)`
  position: absolute;
  left: 1.125rem;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  width: 1.25rem;
  height: 1.25rem;
`

const SearchInput = styled(Input)`
  input {
    background: none;
    padding: 1.25rem 1rem 1.25rem 3.5rem;
    color: ${props => props.theme.white400};
    border-color: ${props => props.theme.white400};
    border-radius: 9px;
    font-weight: 400;
    appearance: none;

    &::placeholder {
      color: ${props => props.theme.white400};
    }
  }

  ${media.desktop`
    input {
      padding-right: 10rem;
    }
  `}
`

const SearchButton = styled(Button)`
  display: none;
  position: absolute;
  right: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  align-items: center;
  border-radius: 3px;
  outline: none;
  margin: 0;
  opacity: 1;

  ${media.desktop`
    display: flex;
  `}
`

export const HomeSearchFormValidationSchema = Yup.object().shape({
  query: Yup.string()
    .test(
      'length',
      'Search query must be at least 3 characters',
      value => !value || value.replace(/ /g, '').length >= 3,
    )
    .required('Search query is required'),
})

export interface HomeSearchFormValues {
  query: string
}

interface HomeSearchFormProps {
  placeholder?: string
  buttonText?: string
}

const HomeSearchForm: React.FC<
  FormikProps<HomeSearchFormValues> & HomeSearchFormProps
> = ({ touched, isValid, placeholder, buttonText, dirty }) => (
  <Field name="query">
    {({ field }: FieldProps) => (
      <SearchContainer>
        <SearchIcon name="search" color="white400" />
        <SearchInput
          {...field}
          id={field.name}
          type="search"
          touched={touched.query}
          placeholder={placeholder || 'Find a club or association'}
          label={placeholder || 'Find a club or association'}
          hideError
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          hideLabel
          preventAutoComplete
        />
        <SearchButton type="submit" disabled={!isValid || !dirty}>
          {buttonText || 'Get started'}
        </SearchButton>
      </SearchContainer>
    )}
  </Field>
)

export default HomeSearchForm
