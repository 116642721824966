import { RouteComponentProps } from '@reach/router'
import React from 'react'
import { getLocale } from '../../utils/env'
import { HomeAU } from './components/HomeAU'
import { HomeUK } from './components/HomeUK'

const Home = (_: RouteComponentProps) => {
  const { isUK } = getLocale()

  if (isUK) {
    return <HomeUK />
  }

  return <HomeAU />
}

export default Home
