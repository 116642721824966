import Icon from '@gameonsports/components/cjs/Icon'
import { Text } from '@gameonsports/components/cjs/TextV3'
import React from 'react'
import {
  Figure,
  SportCardBox,
  SportCardCaption,
  SportCardImg,
} from '../Home.styles'

interface SportCardProps {
  name: string
  image: string
  image2x: string
}

export const SportCard = (props: SportCardProps) => {
  return (
    <Figure>
      <SportCardBox>
        <source srcSet={`${props.image2x} 2x`} />
        <SportCardImg src={props.image} alt="" />
      </SportCardBox>
      <SportCardCaption>
        <Text size="30" color="white400" weight="700">
          {props.name}
        </Text>
        <Icon name="right-arrow-strong" color="white400" />
      </SportCardCaption>
    </Figure>
  )
}
