import { mq } from '@gameonsports/components/cjs/_utils/styled-components-utils'
import { Stack } from '@gameonsports/components/cjs/StackV2'
import { Text } from '@gameonsports/components/cjs/TextV3'
import styled, { css } from 'styled-components'
import { Link } from '../../components/Router'
import court1x from './images/court@1x.jpeg'
import court2x from './images/court@2x.jpeg'

export const Figure = styled.figure`
  position: relative;
  margin: 0;
  height: 100%;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.75) 0%,
      rgba(0, 0, 0, 0) 35.34%
    );
  }

  &::after {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.3) 0%,
      rgba(0, 0, 0, 0) 100%
    );

    a:hover & {
      opacity: 1;
    }
  }

  figcaption {
    position: absolute;
    bottom: 1.5rem;
    left: 1rem;
    z-index: 1;
  }
`

// NOTE: if columns is > 5, they will not fit in a single row, so it is turned
// into a 3-column grid that wraps.
export const SportTileContainer = styled.div<{ $columnCount: number }>`
  display: flex;
  align-items: stretch;
  justify-content: ${props =>
    props.$columnCount < 2 ? 'flex-start' : 'center'};
  flex-wrap: wrap;
  margin-left: -0.5rem;
  margin-right: -0.5rem;

  ${props =>
    props.$columnCount > 5 &&
    css`
      ${mq.up('tablet')} {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 0;
      }
    `}
`

export const SportCardBox = styled.picture`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  max-height: 24rem;

  ${mq.up('tablet')} {
    max-height: 18rem;
  }
`

export const SportCardImg = styled.img`
  display: block;
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const SportCardCaption = styled.figcaption`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 2rem);
`

export const SportLink = styled(Link)<{ $sportsCount: number }>`
  position: relative;
  transition: all 0.2s ease-in-out;
  border-radius: 0.75rem;
  overflow: hidden;
  margin: 0.5rem;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  max-width: 100%;

  ${mq.up('desktop')} {
    flex-basis: 33%;
    max-width: 50%;
  }

  ${mq.up('tablet')} {
    flex-basis: 0;
    max-width: ${props => (props.$sportsCount > 5 ? '100%' : 'max-content')};
  }

  &:hover {
    transform: translateY(-1px);
    filter: drop-shadow(1px 2px 2px rgba(0, 0, 0, 0.2));
  }
`

export const CourtBox = styled.section`
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url(${court1x});
  background-size: cover, cover;
  padding: 7rem 1rem;

  @media (min-resolution: 2dppx) {
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
      url(${court2x});
  }

  ${mq.up('desktop')} {
    padding-top: 11rem;
    padding-bottom: 11rem;
  }

  ${mq.up('laptop')} {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }
`

export const Image = styled.img<{ $order?: number }>`
  border-radius: 0.5rem;

  ${mq.up('tablet')} {
    width: 40%;
    order: ${props => props.$order};
  }
`

export const HomeLink = styled.a`
  background-color: ${props => props.theme.colors.grape400};
  color: ${props => props.theme.colors.white400};
  padding: 1rem 1.5rem;
  border-radius: 3rem;
  text-decoration: none;
  line-height: 1;
  font-weight: 700;
  transition: background-color 0.2s ease;

  svg {
    vertical-align: middle;
    margin-left: 1rem;
  }

  &:hover {
    background-color: ${props => props.theme.colors.blackberry400};
  }
`

export const PageBody = styled(Stack)`
  max-width: 62rem;
  width: 100%;
`

export const FullWidthStack = styled(Stack)`
  width: 100%;
`

export const HeroText = styled.h1`
  padding: 0;
  margin: 0;
  font-weight: 700;
  color: ${props => props.theme.white400};
  font-size: 48px;
  line-height: 1;
  text-align: center;
  font-family: 'Athletics-Black';

  ${mq.up('mobile')} {
    font-size: 80px;
  }

  ${mq.up('tablet')} {
    text-align: left;
  }
`

export const ContrastText = styled.span<{ $isBreakingText?: boolean }>`
  display: ${props => (props.$isBreakingText ? 'block' : 'inline')};
  color: ${props => props.theme.watermelon400};

  ${mq.up('tablet')} {
    display: inline;
  }
`

export const SubHeroText = styled.p`
  margin: 0;
  padding: 0;
  line-height: 1;
  color: ${props => props.theme.white400};
  font-size: 20px;
  text-align: center;

  ${mq.up('tablet')} {
    font-size: 24px;
    text-align: left;
  }
`

export const BannerContainer = styled(Stack)`
  margin-top: 0;
  align-items: initial;
  flex-direction: column;
  max-width: 62rem;
  width: 100%;

  ${mq.up('tablet')} {
    margin-top: 1.5rem;
    align-items: center;
    flex-direction: row;
  }
`

export const BannerText = styled(Stack)`
  width: 100%;

  ${mq.up('tablet')} {
    width: 40%;
  }
`
export const BottomBannerText = styled(BannerText)`
  ${mq.up('tablet')} {
    margin-bottom: 3rem;
  }
`

export const WideStack = styled(Stack)`
  width: 100%;
  max-width: 62rem;
`

export const FooterText = styled(Text)`
  font-weight: 900;
  font-family: 'Athletics-Black';
  width: 100%;

  ${mq.up('tablet')} {
    width: 55%;
  }
`
