import { pushLog } from '../../utils/faro'
import aflImage from './images/afl.jpeg'
import aflImage2x from './images/afl@2x.jpeg'
import basketballImage from './images/basketball.jpeg'
import basketballImage2x from './images/basketball@2x.jpeg'
import cricketImage from './images/cricket.jpeg'
import cricketImage2x from './images/cricket@2x.jpeg'
import footballImage from './images/football.jpeg'
import footballImage2x from './images/football@2x.jpeg'
import hockeyImage from './images/hockey.jpeg'
import hockeyImage2x from './images/hockey@2x.jpeg'
import netballImage from './images/netball.jpeg'
import netballImage2x from './images/netball@2x.jpeg'

export enum SportsLocale {
  AU,
  UK,
}

export const getAvailableSports = (locale: SportsLocale) => {
  switch (locale) {
    case SportsLocale.UK:
      return [
        {
          name: 'Basketball',
          link: '/basketball-england',
          image: basketballImage,
          image2x: basketballImage2x,
        },
      ]
    case SportsLocale.AU:
      return [
        { name: 'AFL', link: '/afl', image: aflImage, image2x: aflImage2x },
        {
          name: 'Basketball',
          link: '/basketball',
          image: basketballImage,
          image2x: basketballImage2x,
        },
        {
          name: 'Cricket',
          link: '/cricket',
          image: cricketImage,
          image2x: cricketImage2x,
        },
        {
          name: 'Hockey',
          link: '/hockey',
          image: hockeyImage,
          image2x: hockeyImage2x,
        },
        {
          name: 'Netball',
          link: '/netball-australia',
          image: netballImage,
          image2x: netballImage2x,
        },
        {
          name: 'Football',
          link: '/football-au',
          image: footballImage,
          image2x: footballImage2x,
        },
      ]
    default:
      pushLog(['Locale is not supported'], { context: { locale } })
      return []
  }
}
